import React from "react";
import styled from "styled-components";

const UnderlinedHeading = styled.h2`
  text-decoration: underline;
`;

const Container = styled.div`
  text-align: center;
  overflow-x: auto; /* Add horizontal scroll */
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scroll */
  margin-top: 20px;
  @media (max-width: 768px) {
    overflow-x: auto;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #5764de;
  color: #080710;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;

  white-space: nowrap;

  @media (max-width: 768px) {
    white-space: normal;
    font-size: "6px";
  }
`;

const TasksContent = () => {
  const tasksData = [
    {
      srNo: 1,
      task: `Bring the registration on <a style="color: #014a82;" href="https://unstop.com/p/sankalan-24-sankalan-24-civil-conclave-society-of-civil-engineers-iit-kanpur-844899" target="_blank" rel="noopener noreferrer">This link</a> and submit proof on WhatsApp`,

      submitTo: "7389959652",
      points: "30/registerations",
    },
    {
      srNo: 2,
      task: `Bring registration on Competitons <a style="color: #014a82;" href="https://sankalaniitk.in/competitions" target="_blank" rel="noopener noreferrer">(Competitions link)</a> and submit proof on WhatsApp`,

      submitTo: "7389959652",
      points: "5/competition registeration ",
    },
    {
      srNo: 3,
      task: `Do your registration on <a style="color: #014a82;" href="https://unstop.com/p/sankalan-24-sankalan-24-civil-conclave-society-of-civil-engineers-iit-kanpur-844899" target="_blank" rel="noopener noreferrer">This link</a> and submit proof on WhatsApp`,

      submitTo: "7389959652",
      points: "10",
    },
    {
      srNo: 4,
      task: `If you bring atleast 3 Registerations`,

      submitTo: "7389959652",
      points: "Your Regiseration would be free",
    },
    {
      srNo: 5,
      task: `If you bring atleast 5 Registerations`,

      submitTo: "7389959652",
      points: "Your Registeration+Accomodation would be free",
    },
  ];

  return (
    <Container>
      <UnderlinedHeading>Tasks</UnderlinedHeading>
      <TableWrapper>
        <div
          style={{
            overflowX: "scroll",
            width: "100vw",
            scrollbarWidth: "0",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
            WebkitScrollbar: {
              display: "none",
            },
          }}
        >
          <Table>
            <thead>
              <tr>
                <TableHeader>Sr. No.</TableHeader>
                <TableHeader>Task</TableHeader>
                <TableHeader>Submit To</TableHeader>
                <TableHeader>Points/Rewards</TableHeader>
              </tr>
            </thead>
            <tbody>
              {tasksData.map((task) => (
                <TableRow key={task.srNo}>
                  <TableCell>{task.srNo}</TableCell>
                  <TableCell
                    dangerouslySetInnerHTML={{ __html: task.task }}
                  ></TableCell>
                  <TableCell>{task.submitTo}</TableCell>
                  <TableCell>{task.points}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </div>
      </TableWrapper>
    </Container>
  );
};

export default TasksContent;
