import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatappGroupContent = () => {
  const whatsappGroupLink = "https://chat.whatsapp.com/LJTRRPWKOTd9uDmvf5ecOj";

  return (
    <div>
      <a
        href={whatsappGroupLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: "flex", alignItems: "center" }}
      >
        <FaWhatsapp
          size={40}
          style={{ marginRight: "10px", marginBottom: "5px" }}
        />
        <span style={{ fontSize: "18px" }}>
          Click Here to Join the WhatsApp Group
        </span>
      </a>
    </div>
  );
};

export default WhatappGroupContent;
